// INIT STATE
const initialState = {
  windowDimensions: {
    set: false,
    width: 0,
    height: 0,
    middle: {
      y: 0,
      x: 0
    },
    cursor: {
      y: 0,
      x: 0,
      capture: false,
      mobile: false,
    },
  },

  distanceFromMiddleY: 0,
  distanceFromMiddleX: 0,

  depthOffsets: {
    level1: 0.4,
    level2: 0.35,
    level3: 0.3,
    level3: 0.3,
    level4: 0.25,
    level5: 0.2,
    level6: 0.15,
    level7: 0.1,
    level8: 0.03,
    level9: 0.0,
    level10: 0,
    horizontal: 0.2,
    info: 0.22,
  },

  depthLevels: {
    depth1: "",
    depth2: "",
    depth3: "",
    depth4: "",
    depth5: "",
    depth6: "",
  }
};


// ACTIONS
export const updateWindow = () => ({ type: 'UPDATE_WINDOW'});
export const updateCursor = cursorObj => ({ type: 'UPDATE_CURSOR', payload: cursorObj });

// REDUCER
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_WINDOW':
      return { 
        ...state, 
        windowDimensions: {
          set: true,
          width: window.innerWidth,
          height: window.innerHeight,
          middle: {
            y: window.innerHeight * 0.5,
            x: window.innerWidth * 0.5
          },
          cursor: {
            y: window.innerHeight * 0.5,
            x: window.innerWidth * 0.5
          },
        }
      };
    case 'UPDATE_CURSOR':
      let cursor = payload;
      let middle = state.windowDimensions.middle;
      let depthOffsets = state.depthOffsets;
      let distanceFromMiddleY = (cursor.y - middle.y) * -1;
      let distanceFromMiddleX = (payload.gyro ? cursor.x : cursor.x - middle.x) * -1;
      let depth1 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level7}px,
          ${distanceFromMiddleY * depthOffsets.level8}px,
          1px
        )
      `;
      let depth2 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level6}px,
          ${distanceFromMiddleY * depthOffsets.level7}px,
          1px
        )
      `;
      let depth3 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level5}px,
          ${distanceFromMiddleY * depthOffsets.level5}px,
          1px
        )
      `;
      let depth3info = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.info}px,
          ${distanceFromMiddleY * depthOffsets.info}px,
          1px
        )
      `;
      let depth4 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level4}px,
          ${distanceFromMiddleY * depthOffsets.level4}px,
          1px
        )
      `;
      let depth5 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level3}px,
          ${distanceFromMiddleY * depthOffsets.level3}px,
          1px
        )
      `;
      let depth6 = `
        translate3d(
          ${distanceFromMiddleX * depthOffsets.level1}px,
          ${distanceFromMiddleY * depthOffsets.level1}px,
          1px
        )
      `;

      return { 
        ...state, 
        windowDimensions: {
          ...state.windowDimensions,
          cursor: {
            ...payload,
            capture: true
          }
        },
        depthLevels: {
          depth1: depth1,
          depth2: depth2,
          depth3: depth3,
          depth4: depth4,
          depth5: depth5,
          depth6: depth6,
          depthInfo: depth3info,
        },
        distanceFromMiddleY: distanceFromMiddleY,
        distanceFromMiddleX: distanceFromMiddleX,
      };
    default:
      return state;
  }
};
