// INIT STATE
const initialState = {
  menuOpen: false,
  imagesLoaded: {
    home: false,
    connect: false,
    explore: false,
    learn: false,
    live: false,
    love: false,
    search: false,
  },
  menuOpenItem: 'home',
};

// ACTIONS
export const openMenu = () => ({ type: 'OPEN_MENU'});
export const closeMenu = () => ({ type: 'CLOSE_MENU'});
export const setImageLoaded = pageTitle => ({ type: 'SET_IMAGE_LOADED', payload: pageTitle});
export const setMenuOpenItem = item => ({ type: 'SET_MENU_OPEN_ITEM', payload: item});

// REDUCER
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'OPEN_MENU':
      return { 
        ...state, 
        menuOpen: true
      };
    case 'CLOSE_MENU':
      return { 
        ...state, 
        menuOpen: false
      };
    case 'SET_IMAGE_LOADED':
      return { 
        ...state, 
        imagesLoaded: {
          ...state.imagesLoaded,
          [payload]: true
        }
      };
    case 'SET_MENU_OPEN_ITEM':
      return { 
        ...state, 
        menuOpenItem: payload
      };
    default:
      return state;
  }
};
