// INIT STATE
const initialState = {
  modalOpen: false,
};


// ACTIONS
export const openModal = () => ({ type: 'OPEN_MODAL'});
export const closeModal = () => ({ type: 'CLOSE_MODAL'});

// REDUCER
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'OPEN_MODAL':
      return { 
        ...state, 
        modalOpen: true
      };
    case 'CLOSE_MODAL':
       return { 
        ...state, 
        modalOpen: false
      };
    default:
      return state;
  }
};
