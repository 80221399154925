import { combineReducers } from 'redux';
import movementState from './state-movement';
import modalState from './state-modal';
import siteState from './state-site';

export default combineReducers({ 
  movementState,
  modalState,
  siteState, 
});
